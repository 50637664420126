<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <page-loading :loading="initializing" />

    <m-card v-if="!initializing" :title="$t('shared.edit')">
      <v-form>
        <v-row dense>
          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.pin" :label="$t('coupons.pin')" :rules="'required|max:30'" dir="ltr" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <auto-complete
              v-model="model.userId"
              :label="$t('users.user')"
              itemText="fullName"
              :search="searchUser"
              :select="selectUser"
              hide-details
              class="ma-5"
              dense
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input
              select
              v-model="model.discountType"
              :label="$t('coupons.discountType')"
              :rules="'required'"
              :items="discountTypes"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input
              v-model="model.amount"
              :label="$t('shared.discount')"
              dir="ltr"
              :suffix="discountLabel"
              :rules="discountValidations"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input
              v-model="model.claimLimit"
              :label="$t('coupons.claimLimit')"
              :rules="'numeric'"
              :suffix="$t('shared.times')"
              dir="ltr"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <date-picker v-model="model.availableFrom" :label="$t('coupons.availableFrom')" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <date-picker v-model="model.availableUntil" :label="$t('coupons.availableUntil')" />
          </v-col>

          <v-col cols="12" sm="6" md="4" class="d-flex align-center">
            <v-switch v-model="model.reusable" :label="$t('coupons.reusable')" inset />
          </v-col>

          <v-col cols="12" sm="6" md="4" class="d-flex align-center">
            <v-switch v-model="model.active" :label="$t('shared.active')" inset />
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <page-footer edit @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Edit',
  props: ['id'],
  mixins: [EditForm],
  created() {
    this.getModel()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    getModel() {
      return this.$api.coupons.getById(this.id).then((res) => {
        this.model = res
      })
    },
    submit() {
      return this.$api.coupons
        .update(this.id, this.model)
        .then((res) => {
          this.model = res
          this.progressing = false
          this.$showSuccess(this.$t('coupons.couponUpdated'))
        })
        .catch((err) => {
          this.progressing = false
          this.$showError(err)
        })
    }
  }
}
</script>
